import React, { Component } from 'react';
import classnames from 'classnames';
import { looseFocus } from '../../helper/LooseFocus';
import { isMobileDevice } from "../../helper/isMobileDevice";
import password_hidden from '../../images/password-hover.svg';
import password_visible from '../../images/password-enabled.svg';
import checked from '../../images/checked.svg';
import empty_checked from '../../images/empty-tick.png';
import jwt_decode from 'jwt-decode';
import map from 'lodash.map';
import axios from '../../helper/Axios';
import { NotificationManager } from 'react-notifications';
import { navigate } from "gatsby";
import OAuth from '../login/OAuth';
import config from '../../../config';
import infeedo_logo from '../../images/infeedo-logo.svg';

export default class ResetPassword extends Component {

    state = {
        password: '',
        password_flag: true,
        loading: false,
        length_flag: false,
        case_flag: false,
        space_flag: false,
        user_details: {}
    }

    // If no token go back to home page
    // --------------------------------
    componentDidMount() {
        this.setState({user_details: jwt_decode((sessionStorage.token))})
        if (!sessionStorage.token) {
            navigate('/');
        }
    }

    // On change input values map to state
    // -----------------------------------
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value }, () => this.checkValidations());
    }

    // toggle password flag
    // --------------------
    togglePasswordFlag = () => {
        const { password_flag } = this.state;
        this.setState({ password_flag: !password_flag });
    }

    // XOR helper method
    // -----------------
    keyCharAt(key, i) {
        return key.charCodeAt(Math.floor(i % key.length));
    }

    // XOR encrypt password
    // --------------------
    xor_encrypt(key, data) {
        return map(data, (c, i) => {
            return c.charCodeAt(0) ^ this.keyCharAt(key, i);
        });
    }

    // reset password
    // --------------
    resetPassword = async (e) => {
        e.preventDefault();
        looseFocus();
        const { length_flag, case_flag, space_flag, password } = this.state;
        if (!(length_flag && case_flag && space_flag)) {
            return;
        }
        this.setState({ loading: true });
        let encrypted_password = this.xor_encrypt('rkjbflaejrbgbjefaljkbewf', password);
        const user_id = jwt_decode((sessionStorage.token)).id;
        try {
            const response = await axios.post('/v1/users/changepassword', { password: encrypted_password, user_id })
            if (response.status === 200) {
                NotificationManager.success('Reset Password Successful');
                setTimeout(() => { window.location.href = `${config.beta_dashboard_url}/${response.data.data}` }, 2000);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    NotificationManager.error('Link expired');
                    setTimeout(() => { navigate("/") }, 2000);
                } else {
                    NotificationManager.error(error.response.data || 'Reset Failed');
                }
            } else {
                NotificationManager.error('Something went wrong');
            }
        }
        this.setState({ loading: false });

    }

    checkValidations = () => {

        const { password } = this.state;
        const regularExpression = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{1,25}$/;

        if (password.length > 8 && password.length < 25) {
            this.setState({ length_flag: true });
        } else {
            this.setState({ length_flag: false });
        }
        if (regularExpression.test(password)) {
            this.setState({ case_flag: true });
        } else {
            this.setState({ case_flag: false });
        }
        if (!password.includes(' ') && password.length > 0) {
            this.setState({ space_flag: true });
        } else {
            this.setState({ space_flag: false });
        }

    }

    OAuth = (which) => {
        window.location = `${config.url}auth/${which}`
    }

    render() {
        const { password_flag, password, loading, length_flag, case_flag, space_flag, user_details } = this.state;
        return (
            <div className="login">
                <figure className="logo column mg-bottom-40 has-text-left pd-30">
                    <img width="130px" src={infeedo_logo} alt="logo" className="mg-left-5" />
                </figure>
                <form className="card is-4 is-offset-6 is-10-mobile is-offset-1-mobile column mar-auto" onSubmit={this.resetPassword}>
                    <div className="card-content">
                        <h1 className={classnames('has-text-centered mg-bottom-10', { 'is-size-5': !isMobileDevice(), 'is-size-6': isMobileDevice()})}>
                            <span className="fs-exclude-data has-text-weight-bold has-text-black">Hey {user_details.name}, Welcome to Amber's Universe for the first time :D </span>
                        </h1>
                        <p className="is-size-7 mg-bottom-25 has-text-centered mg-bottom-20">
                            Let's <strong>secure your account by setting a password</strong>
                        </p>
                        <div className="field">
                            <p className="control">
                                <input onChange={this.onChange} value={password} name="password" className="input" type={(password_flag ? 'password' : 'text')} placeholder="Password" id="password" autoFocus required />
                                <button type="button" className="button is-white is-eye" onClick={this.togglePasswordFlag}>
                                    <span><img src={(password_flag ? password_visible : password_hidden)} alt="password" /></span>
                                </button>
                            </p>
                        </div>
                        <div className="content pd-top-12">
                            <ul className="control is-size-7">
                                <li>{length_flag ? <img className="tick-img" src={checked} alt="tick" /> : <img className="tick-img" src={empty_checked} alt="tick" />}Password should be between 8 and 25 characters.</li>
                                <li>{case_flag ? <img className="tick-img" src={checked} alt="tick" /> : <img className="tick-img" src={empty_checked} alt="tick" />}With one digit, one lower case & one upper case letter.</li>
                                <li>{space_flag ? <img className="tick-img" src={checked} alt="tick" /> : <img className="tick-img" src={empty_checked} alt="tick" />}Should not contain any spaces.</li>
                            </ul>
                        </div>
                        <div className="field pd-top-12">
                            <p className="control has-text-right">
                                <button type="submit" className={classnames('button is-link', { 'is-loading': loading })} disabled={!(length_flag && case_flag && space_flag)}>
                                    &nbsp;&nbsp;Submit&nbsp;&nbsp;
                            </button>
                            </p>
                        </div>


                        <OAuth 
                            OAuth={this.OAuth}
                        />
                    </div>
                </form>
            </div>
        )
    }
}
