import React from 'react'

//assets
import google from '../../images/google.svg'
import microsoft from '../../images/microsoft.svg'

function OAuth({
    OAuth
}) {
    return (
        <div className="oauth-login">
            <span className="or-separator">
                OR
            </span>
            <div className="mg-top-15">
                <button className="google-button button has-text-weight-semibold" onClick={OAuth.bind(this, 'google')}><img className="mg-right-10" src={google} title="google-icon-svg" alt="google-logo" />Continue with Google</button>
                <button className="microsoft-button button has-text-weight-semibold" onClick={OAuth.bind(this, 'microsoft')}> <img className="mg-right-10" src={microsoft} title="microsoft-icon-svg" alt="microsoft-logo" /> Continue with Microsoft</button>
            </div>    
        </div>
    )
}

export default OAuth
