import React from "react";
import ResetPassword from '../components/resetpassword/ResetPassword';
import Layout from "../components/layout"
import SEO from "../components/seo"


const PasswordReset = () => (
    <Layout mode={true}>
        <SEO title="Amber by inFeedo | Reset Password" />
        <ResetPassword/>
    </Layout>
)

export default PasswordReset
